<template>
  <div class="Successbox">
    <i class="el-icon-success infoSuccessIcon" />
    <span class="Successbox_info">企业实名成功</span>
    <span class="Successbox_info">数字证书已下发</span>
    <!--<el-button type="primary" class="SuccessButton">完成</el-button>-->
  </div>
</template>

<script>
import '../../../assets/public/public.css'
export default {
  name: 'PersonageSucess'
}
</script>

<style scoped>

</style>
